var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row content-header"},[_c('div',{staticClass:"content-header-left mb-2 col-md-9 col-12"},[_c('div',{staticClass:"row breadcrumbs-top"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0"},[_c('feather-icon',{staticClass:"mr-2",attrs:{"icon":"ArrowLeftIcon","size":"24"},on:{"click":_vm.goBack}}),_vm._v("Treatment Entry ")],1),_vm._m(0)])])])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{attrs:{"action":"apps-data-entry-list","method":"post"},on:{"submit":_vm.addTreatMentList}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Sign/Symptoms"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('ValidationProvider',{attrs:{"name":"Symptoms","rules":{
											required: true,
											length: 3,
										}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.symptomList,"multiple":"","label":"name","clearable:false":"","reduce":function (e) { return e.id; }},model:{value:(_vm.FormData.treatment_symptoms),callback:function ($$v) {_vm.$set(_vm.FormData, "treatment_symptoms", $$v)},expression:"FormData.treatment_symptoms"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Medicine"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"Select Medicine","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.medicineList,"label":"name","clearable:false":""},on:{"input":_vm.setRouteType},model:{value:(_vm.selectedMedicineList),callback:function ($$v) {_vm.selectedMedicineList=$$v},expression:"selectedMedicineList"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Batch No"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":" Select Batch No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.batchList,"label":"batch_no","clearable:false":""},on:{"input":_vm.setBatchData},model:{value:(_vm.selectedBatchList),callback:function ($$v) {_vm.selectedBatchList=$$v},expression:"selectedBatchList"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Route")]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{attrs:{"label":"routeType","type":"text","value":_vm.routeType,"disabled":""},model:{value:(_vm.routeType),callback:function ($$v) {_vm.routeType=$$v},expression:"routeType"}})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" No. of Birds"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"No. of Birds","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"No. of Birds","type":"text","name":"No. of Birds"},model:{value:(_vm.FormData.birds_quantity),callback:function ($$v) {_vm.$set(_vm.FormData, "birds_quantity", $$v)},expression:"FormData.birds_quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Medicine Administrated (Pack)"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"Medicine Administrated (Pack)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Medicine Administrated (Pack)","type":"text","name":"total_stock"},model:{value:(_vm.FormData.medicine_administered),callback:function ($$v) {_vm.$set(_vm.FormData, "medicine_administered", $$v)},expression:"FormData.medicine_administered"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-1",scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Image ")]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Image","rules":{
										mimes: ['image/jpeg', 'image/png'],
										size: 2048,
									}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-row',[_c('b-col',{staticClass:"col-9"},[_c('b-form-file',{attrs:{"accept":".jpg, .png, .gif"},on:{"change":_vm.onFileChange},model:{value:(_vm.FormData.treatment_image),callback:function ($$v) {_vm.$set(_vm.FormData, "treatment_image", $$v)},expression:"FormData.treatment_image"}})],1),_c('b-col',{staticClass:"col-3"},[_c('b-button',{staticClass:"reset-btn",attrs:{"variant":"outline-primary"},on:{"click":_vm.resetImage}},[_vm._v("X")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-card-text',[_vm._v("Allowed JPG, GIF or PNG. Max size of 2 Mb")]),(_vm.url)?_c('div',[_c('div',{staticClass:"profile-image"},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":_vm.url,"height":"90"}})])]):_vm._e()],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1 mb-3",attrs:{"type":"submit","variant":"primary","value":"Submit"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumb-wrapper"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item active"},[_c('span',{attrs:{"aria-current":"location"}},[_vm._v(" Add ")])])])])}]

export { render, staticRenderFns }